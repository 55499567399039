import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./Component/Header";
import Home from "./Component/Home";
import About from "./Component/About";
import Blog from "./Component/Blog";
import Services from "./Component/Services";
import Contact from "./Component/Contact";
import BlogDetails from "./Component/BlogDetails";
import Footer from "./Component/Footer";
import ScrollToTop from "./Component/ScrollToTop";
import TermsConditions from "./Component/TermsConditions";
import RefundPolicy from "./Component/RefundPolicy";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import MetaData from "./Component/MetaData";


const App = () => {
  return (
    <>
      <Router>
    
        <ScrollToTop />

        <Header />

        <Routes>
          <Route exact="true" path="/" element={<Home />} />
          <Route exact="true" path="/about" element={<About />} />
          <Route exact="true" path="/services" element={<Services />} />
          <Route exact="true" path="/blog/:language" element={<Blog />} />
          <Route exact="true" path="/contact" element={<Contact />} />
          <Route exact="true" path="/:lang/:slug" element={<BlogDetails />} />
          <Route exact="true" path="/:slug" element={<BlogDetails />} />
          <Route exact="true" path="/*" element={<Navigate to="/" />} />
          <Route
            exact="true"
            path="/terms-conditions"
            element={<TermsConditions />}
          />
          <Route
            exact="true"
            path="/privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            exact="true"
            path="/refund-policy"
            element={<RefundPolicy />}
          />
          <Route exact="true" path="/metadata" element={<MetaData />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
